var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "4",
      "xl": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "items": ['Count by Task', 'Count by Delegate']
    },
    model: {
      value: _vm.mode,
      callback: function callback($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  })], 1)], 1)], 1)], 1), _vm.mode == 'Count by Task' ? _c('v-row', _vm._l(_vm.stats['task_completion'], function (card) {
    return _c('v-col', {
      key: card,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "4",
        "xl": "3"
      }
    }, [_c('v-card', [_c('v-card-title', {
      staticClass: "primary py-2 white--text text-subtitle-2"
    }, [_vm._v(_vm._s(card.task)), _c('v-spacer'), _vm._v(_vm._s(card.total))], 1), _c('v-card-text', {
      staticClass: "pa-0"
    }, [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, _vm._l(card.counts, function (count) {
      return _c('v-list-item', {
        key: count.delegate
      }, [_c('v-list-item-text', {
        staticClass: "py-0 my-0"
      }, [_vm._v(_vm._s(count.delegate))]), _c('v-spacer'), _c('v-list-item-text', {
        staticClass: "py-0 my-0 text-subtitle-2"
      }, [_vm._v(_vm._s(count.count))])], 1);
    }), 1)], 1)], 1)], 1);
  }), 1) : _vm._e(), _vm.mode == 'Count by Delegate' ? _c('v-row', _vm._l(_vm.stats['delegate_completion'], function (card) {
    return _c('v-col', {
      key: card,
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "4",
        "xl": "3"
      }
    }, [_c('v-card', [_c('v-card-title', {
      staticClass: "primary py-2 white--text text-subtitle-2"
    }, [_vm._v(_vm._s(card.delegate)), _c('v-spacer'), _vm._v(_vm._s(card.total))], 1), _c('v-card-text', {
      staticClass: "pa-0"
    }, [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, _vm._l(card.counts, function (count) {
      return _c('v-list-item', {
        key: count.delegate
      }, [_c('v-list-item-text', {
        staticClass: "py-0 my-0"
      }, [_vm._v(_vm._s(count.task))]), _c('v-spacer'), _c('v-list-item-text', {
        staticClass: "py-0 my-0 text-subtitle-2"
      }, [_vm._v(_vm._s(count.count))])], 1);
    }), 1)], 1)], 1)], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }