<template>
  <div>

    <v-card class="mb-4">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="4" xl="3">
            <v-select
              dense hide-details outlined
              v-model="mode"
              :items="['Count by Task', 'Count by Delegate']"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row v-if="mode=='Count by Task'">
      <v-col cols="12" md="6" lg="4" xl="3" v-for="card in stats['task_completion']" :key="card">
        <v-card>
          <v-card-title class="primary py-2 white--text text-subtitle-2">{{ card.task }}<v-spacer />{{ card.total }}</v-card-title>
          <v-card-text class="pa-0">
            <v-list dense>
              <v-list-item v-for="count in card.counts" :key="count.delegate" class="">
                <v-list-item-text class="py-0 my-0">{{ count.delegate }}</v-list-item-text>
                <v-spacer />
                <v-list-item-text class="py-0 my-0 text-subtitle-2">{{ count.count }}</v-list-item-text>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="mode=='Count by Delegate'">
      <v-col cols="12" md="6" lg="4" xl="3" v-for="card in stats['delegate_completion']" :key="card">
        <v-card>
          <v-card-title class="primary py-2 white--text text-subtitle-2">{{ card.delegate }}<v-spacer />{{ card.total }}</v-card-title>
          <v-card-text class="pa-0">
            <v-list dense>
              <v-list-item v-for="count in card.counts" :key="count.delegate" class="">
                <v-list-item-text class="py-0 my-0">{{ count.task }}</v-list-item-text>
                <v-spacer />
                <v-list-item-text class="py-0 my-0 text-subtitle-2">{{ count.count }}</v-list-item-text>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<style lang='scss'>
</style>

<script>
import { mapActions, /*mapGetters*/ } from 'vuex'
import moment from 'moment'

export default {
  name: 'QMS_Completion',

  components: {  },

  props: {
    date_from: { required: true },
    date_to: { required: true },
  },

  data: () => ({
    loading: false,
    stats: {},
    mode: 'Count by Task'
  }),

  computed: {
  },

  watch: {
    date_from(){ this.reload(); },
    date_to(){ this.reload(); },
  },

  methods:{
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('UI', ['SHOW_NAVBARS', 'SET_PAGE_TITLE', 'NAVIGATE']),
    ...mapActions('API', ['API']),

    formatDate(date){ return date ? moment(date).format("DD/MM/YY") : null; },

    reload(){
      this.stats = [];
      if(!this.date_from || !this.date_to) return;
      let data = { start: this.date_from, end: this.date_to };
      this.loading = true;
      this.API({ method: 'POST', endpoint: `reports/qms_completion`, data })
      .then(data => {
        this.stats = data;
      })
      .finally(() => { this.loading = false; });
    },

  },
  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS();
      this.SET_PAGE_TITLE('QMS Completion');
      this.reload();
    });
  }
};
</script>
